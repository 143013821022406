import React,{ lazy, Suspense }  from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { CssBaseline } from '@material-ui/core';

const App = lazy(() => import("./App"));

function AppLoader() {
  return (
    <Suspense fallback={<div className="loading">Loading...</div>}>
      <App />
    </Suspense>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <CssBaseline/>
      <AppLoader />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
